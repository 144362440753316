<template>
  <div v-if="data">
    <div class="grid grid-cols-1 gap-7">
      <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-7">
        
        <div
          class="bg-lightblue-100  p-6"
          v-for="server in data"
          v-bind:key="server.id"
        >
       <router-link :to="'/servers/' + server.name">
          <p class="text-sm font-semibold text-black mb-2">Server</p>

          <div class="flex flex-col  items-center justify-between">
            <h2 class="text-2l leading-9 font-bold text-black">{{ server.name }}</h2>
            <div class="flex items-center gap-1">
              <!--<p class="text-xs text-green-400 leading-[18px]">{{ server.status }}</p>-->

              <span>{{ server.datacenter.location.city }} <small>{{ server.datacenter.name }}</small></span>
              <!--<svg class="text-green-400" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z" fill="currentcolor"></path></svg>-->
            </div>
          </div>



          <!--<div class="relative">
            <button
              @click="show = !show"
              class="flex items-center p-2 text-black-100 bg-pink-100 rounded-md"
            >
              <span class="mr-4">Operazioni</span>
              <svg
                class="w-5 h-5 text-black-100 dark:text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
      
            <div
              v-show="show"
              class="
                absolute
                left-0
                py-2
                mt-0
                bg-pink-100
                rounded-md
                shadow-xl
                w-44
              "
            >
              <div
                
                class="
                  block
                  px-4
                  py-2
                  text-sm text-black-100
                  hover:bg-gray-400 hover:text-black-100
                "
              >
                REDIS Flush
              </div>
              <div
                
                class="
                  block
                  px-4
                  py-2
                  text-sm text-black-100
                  hover:bg-gray-400 hover:text-black-100
                "
              >
                PHP OPCache Flush
              </div>
              <div
                
              class="
                block
                px-4
                py-2
                text-sm text-black-100
                hover:bg-gray-400 hover:text-black-100
              "
            >
              Htcacheclean
            </div>
            <div
                
              class="
                block
                px-4
                py-2
                text-sm text-black-100
                hover:bg-gray-400 hover:text-black-100
              "
            >
              Take files backup now
            </div>
             
            </div>
          </div>-->

         


   

       </router-link>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const Axios = axios.create({
    baseURL: 'https://console.cloud.integrate.digital/api/'
  })

export default {
  name: "Servers",
  data() {
    return {
      data: [],
      show: false,
    };
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
  },
  mounted() {
    const self = this;
    const signInToken = localStorage.getItem('signInToken');
    Axios.defaults.headers.common = {'Authorization': `Bearer ${signInToken}`}

    

    Axios.post('?task=getServers')
      .then(function (response) {
        if(response.data.error !== undefined && 
          response.data.error === 'Expired token')
        {
          localStorage.removeItem('signInToken');
          self.$router.push('/login')
        }

        self.data = response.data;
        self.$root.$emit('updateQueue');
        console.log('data', self.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>
