<template>
  <div class="h-[calc(100vh-73px)] overflow-y-auto overflow-x-hidden">
    <div class="p-4 sm:p-7 min-h-screen">
    <Servers />
  </div>
</div>
</template>
<script>
// @ is an alias to /src
import Servers from '@/components/Servers.vue'

export default {
  name: 'Home',
  components: {
    Servers
  }
}
</script>